import React from 'react';  
import SentimentAnalyzers from './pages/home';  
import 'bootstrap/dist/css/bootstrap.min.css';  
  
const App = () => {  
    return (  
        <div>  
            <SentimentAnalyzers />  
        </div>  
    );  
};  
  
export default App;  
